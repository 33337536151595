<route>
{
  "meta": {
    "permission": [
      "sequences.view_sequence"
    ]
  }
}
</route>

<template>
  <i-table
    :title="$tc('sequence', 2)"
    :headers="headers"
    app="sequences.sequence"
    api="sequence"
    dontCreate
    dontRemove
    @click:edit="changeRoute"
  >
    <template v-slot:item.prefix="{ value }">
      <span :class="value === '' ? 'red--text' : ''">
        {{ value === '' ? $t('dontAvalaible') : value }}
      </span>
    </template>
    <template v-slot:item.suffix="{ value }">
      <span :class="value === '' ? 'red--text' : ''">
        {{ value === '' ? $t('dontAvalaible') : value }}
      </span>
    </template>
  </i-table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    headers() {
      return [
        {
          text: this.$t('model'),
          value: 'model'
        },
        {
          text: this.$t('prefix'),
          value: 'prefix'
        },
        {
          text: this.$t('padding'),
          value: 'padding'
        },
        {
          text: this.$t('next_number'),
          value: 'next_number'
        },
        {
          text: this.$t('suffix'),
          value: 'suffix'
        },
        {
          text: this.$t('number_increment'),
          value: 'number_increment'
        },
        {
          text: this.sequenceBtn.edit ? this.$t('action') : '',
          value: this.sequenceBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    sequenceBtn() {
      return {
        edit: this.getPermissions([`sequences.change_sequence`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'sequence-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
